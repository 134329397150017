<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                class="overflow-x-auto"
                wrap
        >
            <template v-if="loaded">
                <data-table ref="datatable"
                            :api-end-point="apiurl"
                            :isCustomFilter="false"
                            :search="search"
                            :sort-order="sortOrder"
                            :table-name="'Promoters'"
                            :isDetail="true"
                            :isExport="true"
                            :pre-selected-filters="filters.selectedFilters"
                >
                    <template slot="top-header">
                      <div class="feature-toggle flex-display justify-end">
                        <div class="toggle-switch">
                            <label class="switch-label">Enable Promotions &nbsp;</label>
                            <label class="switch">
                                <input type="checkbox" name="is_30_min_session_allowed" v-model="enablePromotions" :true-value="true" :false-value="false" v-on:change="togglePromotions">
                                <span class="slider round"></span>
                            </label>
                        </div>
                      </div>
                    </template>
                    <template slot="datatable-actions">
                        <div class="action-buttons">
                            <button class="btn btn-primary" @click="showPromoterModal">{{ addPromoterButtonText }}</button>
                        </div>
                    </template>

                    <template slot="filters">
                        <filters :filters="filters.timeFilters"></filters>
                    </template>
                </data-table>
            </template>

            <!-- Add Promoter -->
            <add-promoter v-if="addPromoterDialog"
                          :visible="addPromoterDialog"
                          :action="action"
                          :promoter="selectedPromoter"
                          v-on:addPromoter="createToken"
                          v-on:updatePromoter="updatePromoterData"
                          v-on:hide="hidePromoterModal"
            >
            </add-promoter>

            <!-- Promoter Detail -->
            <promoter-detail v-if="promoterDetailDialog"
                             :visible="promoterDetailDialog"
                             :rowData="selectedPromoter"
                             v-on:hide="promoterDetailDialog=false"
                             v-on:edit="editPromoter"
                             v-on:delete="showDeleteConfirmationModal"
                             v-on:updateStatus="updateStatus"
            >
            </promoter-detail>

            <!-- delete dialog -->
            <confirmation v-if="deleteDialog" :visible="deleteDialog">
                <template slot="title">
                    Are you sure?
                </template>
                <template slot="desc">
                    This Promoter will be deleted. This action cannot be undone.
                </template>
                <template slot="actions">
                    <a class="cancel-model" @click="hideDeleteConfirmationModal">Cancel</a>
                    <button class="btn modal-action-button confirmation-delete" @click="deletePromoter">Delete</button>
                </template>
            </confirmation>

        </v-layout>
    </v-container>
</template>

<script>
    import DataTable from "../components/common/dataTable/DataTable";
    import Filters from "../components/common/dataTable/filters/Filters";
    import AddPromoter from "../components/people/actions/AddPromoter.vue";
    import PromoterDetail from "../components/people/PromoterDetail";
    import Confirmation from "../components/common/Confirmation";

    export default {
        name: "Promoters",
        components: {DataTable, Filters, AddPromoter, PromoterDetail, Confirmation},
        data() {
            return {
                addPromoterDialog: false,
                addPromoterButtonText: 'Add Promoter',
                enablePromotions: 1,
                apiurl: 'promoters',
                search:{
                    placeholder:  'Search by name or discount code',
                    searchAble: true
                },
                sortOrder: [
                    {
                        field: 'name',
                        sortField: 'name',
                        direction: 'desc'
                    }
                ],
                detailUrl: 'promoters/details/',
                filters: null,
                loaded: false,
                selectedPromoter: null,
                promoterDetailDialog: false,
                deleteDialog: false,
                action: 'add'
            }
        },
        events: {
            'create-new': function () {
                this.showPromoterModal();
            },
            'open-details': function(data) {
                this.selectedPromoter = data;
                this.promoterDetailDialog = true;
            },
        },
        created() {
            this.fetchFilters();
            this.getPromotionsFeature();
        },
        methods: {
            getPromotionsFeature() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.get(this.url+'features/promotions', {
                }).then(function (response) {
                    loader.hide();
                    self.enablePromotions = response.data.is_active;
                }).catch(function (error) {
                    loader.hide();
                })
            },
            showPromoterModal() {
                this.action = 'add';
                this.selectedPromoter = null;
                this.addPromoterDialog = true;
            },
            hidePromoterModal() {
                this.addPromoterDialog = false;
            },
            addPromoter(promotorData) {
                let formData = new FormData();
                for ( let key in promotorData ) {
                    if(promotorData.hasOwnProperty(key)) {
                        formData.append(key, promotorData[key]);
                    }
                }
                let loader = this.$loading.show();
                this.$http.post(this.url + 'promoter', formData).then( response => {
                    this.$events.fire('vuetable:reload');
                    loader.hide();
                    this.hidePromoterModal();
                    this.$toastr('success', response.data.message, '');
                }).catch( error => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message, '');
                });
            },
            updatePromoter(id, data) {
                data.id = id;
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url + 'promoter/update', data).then(function (response) {
                    self.$events.fire('vuetable:reload');
                    loader.hide();
                    self.promoterDetailDialog = false;
                    self.hidePromoterModal();
                    self.$toastr('success', response.data.message, '');
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, '');
                });
            },
            createToken(data, id){
                let self = this;
                const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                let loader = this.$loading.show();
                stripe.createToken('bank_account', {
                    country: 'GB',
                    currency: 'gbp',
                    routing_number: data.sort_code,
                    account_number: data.account_number,
                    account_holder_name: data.name,
                    account_holder_type: 'individual',
                }).then(function(result) {
                    if (result.error) {
                        self.$toastr('error', result.error.message, '');
                        loader.hide();
                    } else {
                        data.token = result.token.id;
                        id ? self.updatePromoter(id, data) : self.addPromoter(data);
                        loader.hide();
                    }
                });
            },
            showDeleteConfirmationModal () {
                this.deleteDialog = true;
                this.promoterDetailDialog = false;
            },
            hideDeleteConfirmationModal () {
                this.deleteDialog = false;
                this.promoterDetailDialog = true;
            },
            editPromoter() {
                this.action = 'edit';
                this.addPromoterDialog = true;
            },
            deletePromoter() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.delete(this.url + 'promoter', {
                    data: {
                        promoter_id: self.selectedPromoter.id
                    }
                }).then(function (response) {
                    self.deleteDialog = false;
                    self.promoterDetailDialog = false;
                    self.$events.fire('vuetable:reload');
                    loader.hide();
                    self.$toastr('success', response.data.message, '');
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, '');
                });
            },
            updateStatus (promoter_id, status) {
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url + 'promoter/update_status', {
                    promoter_id: promoter_id,
                    status: status == 0 ? 'inactive' : 'active'
                }).then(function (response) {
                    self.$events.fire('vuetable:reload');
                    loader.hide();
                    self.$toastr('success', response.data.message, '');
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, '');
                });
            },
            togglePromotions() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url + 'features/promotions/toggle', {
                    status: self.enablePromotions
                }).then(function (response) {
                    loader.hide();
                }).catch(function (error) {
                    loader.hide();
                });
            },
            updatePromoterData(id, data) {
                if ( this.selectedPromoter.account_detail.account_number !== data.account_number ||
                    this.selectedPromoter.account_detail.sort_code !== data.sort_code
                ) {
                    this.createToken(data, id);
                }
                else {
                    this.updatePromoter(id, data)
                }
            },
            fetchFilters(){
                this.$http.get(this.url+'filters/promoters').then((res) => {
                    if(res.data) {
                        this.filters = res.data;
                        this.loaded = true;
                    } else {
                        alert('there is some problem');
                    }
                });
            },
        }
    }
</script>
